import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Linkedin from "../Assets/Icons/linkedin.svg";
import Mail from "../Assets/Icons/mails.svg";
import Phone from "../Assets/Icons/phone.svg";
import "../index.css";
import Computer from "./Computer";
import GitHub from "../Assets/Icons/icons8-github.svg";
function CvEsMob() {
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1100) {
        navigate("/");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigate]);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const slideIntervalRef = useRef(null);

  const accordionItems = [
    {
      title: "EXPERIENCIA LABORAL",
      content: (
        <>
          <div style={{ marginTop: "-25px" }}>
            <p style={{ marginTop: "5px" }}>
              <span className="regular">LABBA STUDIO </span> <br />
              <span className="regular">
                Head of IT & Full Stack Developer{" "}
              </span>
              - Mayo 2022 - present
              <br />
              <span>
                - Liderazgo del equipo de IT, supervisando proyectos y
                asegurando la calidad del desarrollo.
              </span>
              <br />
              <span>
                - Desarrollo de aplicaciones web usando React, Angular, y Next
                para el front-end, y Node y Express para el back-end.
              </span>
              <br />
              <span>
                - Mantenimiento y optimización de bases de datos con MongoDB y
                MySQL.
              </span>
              <br />
              <span>
                - Colaboración con equipos de diseño y marketing para soluciones
                digitales.
              </span>
            </p>
            <p>
              <span className="regular">LATECH FACTORY </span> <br />
              <span className="regular">
                Contractor Full Stack Developer{" "}
              </span>{" "}
              - Mayo 2023 - present
              <br />
              <span>
                - Desarrollo completo de aplicaciones web, incluyendo front-end
                y back-end.
              </span>
              <br />
              <span>
                - Construcción y personalización de interfaces de usuario y
                componentes visuales.
              </span>
            </p>

            <p>
              <span className="regular">
                Tribu Data. Revolutionary Market Research Agency
              </span>
              <br />
              Software Development - USA FL Miami <br />
              Marzo 2019 - Febrero 2022 <br />
              - Full Stack Developer. <br />
              - Procesamiento, análisis y visualización de datos utilizando
              Google Data Studio y Tableau.
              <br />
              - Análisis web e informes de rendimiento. <br /> - Desarrollo e
              implementación de estrategias digitales. (Anuncios en Facebook)
            </p>
          </div>
        </>
      ),
    },
    {
      title: "FORMAción academica",
      content: (
        <div style={{ marginTop: "-20px" }}>
          <p>
            <span className="regular">IRONHACK MADRID - Bootcamp </span> <br />
            Web development using front and back end technologies: HTML5, CSS3,
            JavaScript (ES6). Nodejs, Express.js, MongoDB. React.js. Oct. 2021 -
            Feb. 2022
          </p>
          <p style={{ marginTop: "-10px" }}>
            <span className="regular">
              DIGITAL HOUSE - Programación Full Stack
            </span>
            <br />
            Web development using front and back end technologies: HTML5, CSS3,
            JavaScript (ES6). PHP, Laravel, MySQL. React.js. Marzo 2019 - Sept.
            2019
          </p>
          <p style={{ marginTop: "-10px" }}>
            <span className="regular">
              Harvard University | CS50: Introduction to Computer Science
            </span>
            <br />
            Adquisición de conocimientos fundamentales en programación y
            resolución de problemas. Aprendizaje de varios lenguajes de
            programación, incluyendo C, Python y JavaScript.
          </p>
          <p style={{ marginTop: "-5px" }}>
            <span className="regular">
              EDUCACIÓN IT - Introducción en programación Python. 2020
            </span>
          </p>
          <p style={{ marginTop: "-10px" }}>
            <span className="regular">
              EDUCACIÓN IT - JavaScript Desarrollador Avanzado. 2020
            </span>
          </p>
          <p style={{ marginTop: "-10px" }}>
            <span className="regular">Google Analytics. 2019 </span>
          </p>
          <p style={{ marginTop: "-10px" }}>
            <span className="regular">Godspell College </span>
            <br />
            Bachillerato bilingüe con orientación en Humanidades 2016
          </p>
        </div>
      ),
    },
    {
      title: "HErramientas",
      content: (
        <div style={{ marginTop: "-20px" }}>
          <p>
            HTML 5 - CSS - JavaScript - React - Node - Express - PHP - Strapi -
            NextJS - MongoDB - MySQL - Wordpress - Tableau - Google Analytics -
            Google Data Studio - Adobe Creative Cloud
          </p>
        </div>
      ),
    },
    {
      title: "HABILIDADES",
      content: (
        <div style={{ marginTop: "-20px" }}>
          <p>
            Resolución de conflictos
            <br />
            Comunicación
            <br />
            Capacidad de adaptación
            <br />
            Trabajo en equipo
            <br />
            Creativadad
          </p>
        </div>
      ),
    },
    {
      title: "PROYECTOS",
      content: (
        <div className="academica-section">
          <Computer />
        </div>
      ),
    },
    {
      title: "CONTACTO",
      content: (
        <div style={{ marginTop: "-20px" }}>
          <p>
            <img src={Mail} width={10} style={{ marginRight: "7px" }} />
            <a href="mailto:manuelnpascual@gmail.com">
              manuelnpascual@gmail.com
            </a>
          </p>
          <p>
            <img src={Phone} width={10} style={{ marginRight: "7px" }} />
            <a href="https://wa.me/+5491151632960" target="_blank">
              +54 9 11 5163 2960
            </a>
          </p>
          <p>
            <img src={Phone} width={10} style={{ marginRight: "7px" }} />
            <a href="https://wa.me/+34634269453" target="_blank">
              +34 634 269 453
            </a>
          </p>
          <p>
            <img src={Linkedin} width={10} style={{ marginRight: "7px" }} />
            <a href="https://linkedin.com/in/pascualmanuel" target="_blank">
              linkedin.com/in/pascualmanuel
            </a>
          </p>
          <p>
            <img src={GitHub} width={10} style={{ marginRight: "7px" }} />
            <a href="https://github.com/pascualmanuel/" target="_blank">
              github.com/pascualmanuel/
            </a>
          </p>
        </div>
      ),
    },
    {
      title: "IDIOMAS",
      content: (
        <p style={{ marginTop: "-20px" }}>
          INGLES <br />
          avanzado
        </p>
      ),
    },
  ];

  return (
    <>
      <Link to="/mob-en">
        <p
          style={{
            marginTop: "20px",
            marginRight: "60px",
            fontWeight: "600",
            textDecoration: "none",
            textAlign: "right",
          }}
        >
          English
        </p>
      </Link>

      <div className="mobile-cont">
        <div className="head-mb">
          <div className="foto-manuel"></div>
          <h2 style={{ marginBottom: "0px" }}> MANUEL PASCUAL</h2>
          <h3 style={{ marginTop: "10px" }}>Web Developer</h3>
          <p
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              maxWidth: "600px",
            }}
          >
            Soy un Desarrollador Web con experiencia laboral en Marketing
            Digital. Me di cuenta que la resolución de problemas, el diseño y el
            mundo digital es lo que más me gusta. Profesional altamente dedicado
            y automotivado con habilidades efectivas de liderazgo y
            comunicación. <br /> Estoy en busca de un rol desafiante como
            desarrollador web que me permita contribuir de manera significativa
            a equipos, impulsar el éxito de proyectos y expandir mis horizontes
            profesionales.
          </p>
        </div>
        <div className="cont-accordion">
          <div className="accordion">
            {accordionItems.map((item, index) => (
              <div
                key={index}
                className={`accordion-item ${
                  index === activeIndex ? "active" : ""
                }`}
              >
                <div
                  className="accordion-title"
                  onClick={() => handleClick(index)}
                >
                  {item.title}
                </div>
                <div className="accordion-content">
                  {index === activeIndex && item.content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CvEsMob;
