import React, { useState, useEffect, useRef } from "react";
import "../index.css";
import Slide0 from "../Assets/Slides/EphimeroSlide.webp";
import Slide1 from "../Assets/Slides/Slide1.webp";
import Slide2 from "../Assets/Slides/Slide2.webp";
import Slide3 from "../Assets/Slides/Slide3.png";
import Slide4 from "../Assets/Slides/Slide4.webp";
import Slide5 from "../Assets/Slides/Slide5.webp";
import Slide6 from "../Assets/Slides/Slide6.png";
import Slide7 from "../Assets/Slides/Slide7.webp";
import Slide8 from "../Assets/Slides/Slide8.png";
import Slide9 from "../Assets/Slides/Slide-9.webp";
import SlideInmo from "../Assets/Slides/Slide-inmo.png";
import Video1 from "../Assets/Slides/Video1.mp4";
import Video2 from "../Assets/Slides/Video2.mp4";
import Video3 from "../Assets/Slides/Video3.mp4";
import Video4 from "../Assets/Slides/Video4.mp4";
import Video5 from "../Assets/Slides/Video5.mp4";
import LabbaPoster from "../Assets/Slides/labbaPoster.png";
import { ReactSVG } from "react-svg";

import LinkIcon from "../Assets/Icons/link-icon.svg";
function Computer() {
  const slides = [
    {
      type: "video",
      url: Video1,
      title: "Labba Studio",
      subtitle: "React - CSS & SASS",
      link: "https://labba.studio",
      poster: LabbaPoster,
    },
    {
      type: "image",
      url: Slide0,
      title: "Ephimero",
      subtitle: "Shopify - Ruby Liquid - CSS",
      link: "https://ephimero.co",
    },
    {
      type: "video",
      url: Video2,
      title: "redbee io",
      subtitle: "Next JS - Gatsby - Strapi - PostgreSQL ",
      link: "",
      isLoop: true,
      poster: Slide6,
    },
    {
      type: "image",
      url: Slide1,
      title: "MannoApp",
      subtitle: "React - CSS & SASS",
      link: "https://mannoapp.com",
    },
    {
      type: "image",
      url: SlideInmo,
      title: "Inmobiliare",
      subtitle: "Wordpress",
      link: "https://inmobiliare.com/",
    },
    {
      type: "image",
      url: Slide2,
      title: "Daewoo CL",
      subtitle: "React - Strapi - Next JS - CSS & SASS",
      link: "https://demo-daewoocl.onrender.com/",
    },
    {
      type: "video",
      url: Video4,
      title: "Morgenstern",
      subtitle: "React - CSS & SASS",
      link: "https://holamorgenstern.com",
      isLoop: true,
      poster: Slide8,
    },
    {
      type: "video",
      url: Video5,
      title: "Sunsetstrip",
      subtitle: "Wordpress",
      link: "https://sunsetstrip.life/",
      isLoop: true,
      poster: Slide4,
    },
    {
      type: "video",
      url: Video3,
      title: "EstebanRauch",
      subtitle: "React - Wordpress - CSS & SASS",
      link: "https://estebanrauch.com",
      isLoop: true,
      poster: Slide3,
    },
    {
      type: "image",
      url: Slide7,
      title: "Frenchitecture",
      subtitle: "Wordpress",
      link: "https://frenchitecture.com/frenchitecture/",
    },
    {
      type: "image",
      url: Slide9,
      title: "Marusia - colab.",
      subtitle: "Next JS - React - Node JS - CSS & SASS",
      link: "https://marusiai.com/",
    },
    {
      type: "image",
      url: Slide5,
      title: "FrancoBeverati",
      subtitle: "Wordpress",
      link: "https://francobeverati.com",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const slideIntervalRef = useRef(null);
  const videoRef = useRef(null);

  let startVideo = false;
  useEffect(() => {
    startSlideInterval();

    return () => {
      clearInterval(slideIntervalRef.current);
    };
  }, [slides.length]);

  // useEffect(() => {
  //   if (slides[currentSlide].type === "video" && videoRef.current) {
  //     videoRef.current.addEventListener("loadeddata", startSlideInterval, {
  //       once: true,
  //     });
  //   } else {
  //     startSlideInterval();
  //   }

  //   return () => {
  //     if (videoRef.current) {
  //       videoRef.current.removeEventListener("loadeddata", startSlideInterval);
  //       console.log("first");
  //       startVideo = true;
  //       console.log(startVideo);
  //     }
  //   };
  // }, [currentSlide]);

  const startSlideInterval = () => {
    clearInterval(slideIntervalRef.current);
    slideIntervalRef.current = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 20000);
  };

  const stopSlideInterval = () => {
    clearInterval(slideIntervalRef.current);
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + slides.length) % slides.length
    );
  };

  const currentSlideData = slides[currentSlide];

  return (
    <div onMouseEnter={stopSlideInterval} onMouseLeave={startSlideInterval}>
      <div className="slide-title-container">
        <h4 className="current-slide-title">
          <a href={currentSlideData.link} target="_blank" className="subtitle">
            {currentSlideData.title}
          </a>
          {/* <ReactSVG
            src={LinkIcon}
            beforeInjection={(svg) => {
              svg.classList.add("svg-class-name");
              svg.setAttribute("style", "width: 20px");
            }}
          /> */}
          <br />
          <br />
          <span className="">{currentSlideData.subtitle}</span>
        </h4>
      </div>
      <div className="mask">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            {slide.type === "image" ? (
              <img
                src={slide.url}
                alt={slide.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            ) : (
              <video
                ref={index === currentSlide ? videoRef : null}
                poster={slide.poster}
                src={slide.url}
                controls={false}
                autoPlay={true}
                muted={true}
                loop={slide.isLoop}
                playsInline
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            )}
          </div>
        ))}
      </div>
      <div className="controls">
        <div
          className="prev-icon"
          style={{ cursor: "pointer" }}
          onClick={prevSlide}
        ></div>
        <i
          className="next-icon"
          style={{ cursor: "pointer" }}
          onClick={nextSlide}
        ></i>
      </div>
    </div>
  );
}

export default Computer;
