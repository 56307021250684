import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Linkedin from "../Assets/Icons/linkedin.svg";
import Mail from "../Assets/Icons/mails.svg";
import Phone from "../Assets/Icons/phone.svg";
import Computer from "./Computer";
import "../index.css";
import GitHub from "../Assets/Icons/icons8-github.svg";

function CvEnMob() {
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1100) {
        navigate("/");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigate]);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const slideIntervalRef = useRef(null);

  const accordionItems = [
    {
      title: "Work experience",
      content: (
        <>
          <div style={{ marginTop: "-25px" }}>
            <p style={{ marginTop: "5px" }}>
              <span className="regular">LABBA STUDIO </span> <br />
              <span className="regular">
                Head of IT & Full Stack Developer{" "}
              </span>
              - Mayo 2022 - present
              <br />
              <span>
                - Leadership of the IT team, overseeing projects and ensuring
                development quality.
              </span>
              <br />
              <span>
                - Development of web applications using React, Angular, and
                Next.js for the front-end, and Node.js and Express for the
                back-end.
              </span>
              <br />
              <span>
                - Maintenance and optimization of databases with MongoDB and
                MySQL.
              </span>
              <br />
              <span>
                - Collaboration with design and marketing teams for digital
                solutions.
              </span>
            </p>
            <p>
              <span className="regular">LATECH FACTORY </span> <br />
              <span className="regular">
                Contractor Full Stack Developer{" "}
              </span>{" "}
              - Mayo 2023 - present
              <br />
              <span>
                - Full development of web applications, including front-end and
                back-end.
              </span>
              <br />
              <span>
                - Building and customizing user interfaces and visual
                components.
              </span>
            </p>

            <p>
              <span className="regular">
                Tribu Data. Revolutionary Market Research Agency
              </span>
              <br />
              Software Development - USA FL Miami
              <br />
              Marzo 2019 - Febrero 2022
              <br />
              - Full Stack Developer.
              <br />
              - Processing, analysis, and visualization of data using Google
              Data Studio and Tableau.
              <br />
              - Web analytics and performance reporting.
              <br />- Development and implementation of digital strategies,
              including Facebook advertising.
            </p>
          </div>
        </>
      ),
    },
    {
      title: "eduaction",
      content: (
        <div style={{ marginTop: "-20px" }}>
          <p>
            <span className="regular">IRONHACK MADRID - Bootcamp </span> <br />
            Web development using front and back end technologies: HTML5, CSS3,
            JavaScript (ES6). Nodejs, Express.js, MongoDB. React.js. oct. 2021 -
            feb. 2022
          </p>
          <p style={{ marginTop: "-10px" }}>
            <span className="regular">
              DIGITAL HOUSE ARGENTINA - Full Stack Developer
            </span>
            <br />
            Web development using front and back end technologies: HTML5, CSS3,
            JavaScript (ES6). PHP, Laravel, MySQL. React.js. march 2019 - sept.
            2019
          </p>
          <p style={{ marginTop: "-10px" }}>
            <span className="regular">
              Harvard University | CS50: Introduction to Computer Science
            </span>
            <br />
            Acquisition of fundamental knowledge in programming and
            problem-solving. Learning various programming languages, including
            C, Python, and JavaScript.
          </p>
          <p style={{ marginTop: "-10px" }}>
            <span className="regular">
              EDUCACIÓN IT - Introduction to Python programming. 2020
            </span>
          </p>
          <p style={{ marginTop: "-10px" }}>
            <span className="regular">
              EDUCACIÓN IT - JavaScript Advanced Developer. 2020
            </span>
          </p>
          <p style={{ marginTop: "-10px" }}>
            <span className="regular">Google Analytics. 2019 </span>
          </p>
          <p style={{ marginTop: "-10px" }}>
            <span className="regular">Godspell College </span>
            <br />
            Bilingual High School with a Humanities focus in 2016
          </p>
        </div>
      ),
    },
    {
      title: "TOOLS",
      content: (
        <div style={{ marginTop: "-20px" }}>
          <p>
            HTML 5 - CSS - JavaScript - React - Node - Express - PHP - Strapi -
            NextJS - MongoDB - MySQL - Wordpress - Tableau - Google Analytics -
            Google Data Studio - Adobe Creative Cloud
          </p>
        </div>
      ),
    },
    {
      title: "Skills",
      content: (
        <div style={{ marginTop: "-20px" }}>
          <p>
            Problem-solving abilities.
            <br />
            Communication
            <br />
            Adaptive Capacity
            <br />
            Teamwork
            <br />
            Creativity
          </p>
        </div>
      ),
    },
    {
      title: "Projects",
      content: (
        <div className="academica-section">
          <Computer />
        </div>
      ),
    },
    {
      title: "CONTACT",
      content: (
        <div style={{ marginTop: "-20px" }}>
          <p>
            <img src={Mail} width={10} style={{ marginRight: "7px" }} />
            <a href="mailto:manuelnpascual@gmail.com">
              manuelnpascual@gmail.com
            </a>
          </p>
          <p>
            {" "}
            <img src={Phone} width={10} style={{ marginRight: "7px" }} />
            <a href="https://wa.me/+5491151632960" target="_blank">
              +54 9 11 5163 2960
            </a>
          </p>
          <p>
            {" "}
            <img src={Phone} width={10} style={{ marginRight: "7px" }} />
            <a href="https://wa.me/+34634269453" target="_blank">
              +34 634 269 453
            </a>
          </p>
          <p>
            <img src={Linkedin} width={10} style={{ marginRight: "7px" }} />
            <a href="https://linkedin.com/in/pascualmanuel" target="_blank">
              linkedin.com/in/pascualmanuel
            </a>
          </p>
          <p>
            <img src={GitHub} width={10} style={{ marginRight: "7px" }} />
            <a href="https://github.com/pascualmanuel/" target="_blank">
              github.com/pascualmanuel/
            </a>
          </p>
        </div>
      ),
    },
    {
      title: "LANGUAGES",
      content: (
        <p style={{ marginTop: "-20px" }}>
          SPANISH <br />
          native
          <br />
          ENGLISH
          <br />
          advanced
        </p>
      ),
    },
  ];
  return (
    <>
      <Link to="/mob-es">
        <p
          style={{
            marginTop: "20px",
            marginRight: "60px",
            fontWeight: "600",
            textDecoration: "none",
            textAlign: "right",
          }}
        >
          Spanish
        </p>
      </Link>

      <div className="mobile-cont">
        <div className="head-mb">
          <div className="foto-manuel"></div>
          <h2 style={{ marginBottom: "0px" }}> MANUEL PASCUAL</h2>
          <h3 style={{ marginTop: "10px" }}>Web Developer</h3>
          <p
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              maxWidth: "600px",
            }}
          >
            I am a Web Developer with work experience in Digital Marketing. I
            realized that problem-solving, design, and the digital world are
            what I enjoy the most. I am a highly dedicated and self-motivated
            professional with effective leadership and communication skills.{" "}
            <br /> I am seeking a challenging role as a web developer that
            allows me to make a significant contribution to teams, drive project
            success, and expand my professional horizons.
          </p>
        </div>
        <div className="cont-accordion">
          <div className="accordion">
            {accordionItems.map((item, index) => (
              <div
                key={index}
                className={`accordion-item ${
                  index === activeIndex ? "active" : ""
                }`}
              >
                <div
                  className="accordion-title"
                  onClick={() => handleClick(index)}
                >
                  {item.title}
                </div>
                <div className="accordion-content">
                  {index === activeIndex && item.content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CvEnMob;
