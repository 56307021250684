import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../index.css";
import Computer from "./Computer";
import Linkedin from "../Assets/Icons/linkedin.svg";
import Mail from "../Assets/Icons/mails.svg";
import Phone from "../Assets/Icons/phone.svg";
import CvManuelEn from "../Assets/resume/cv-manuel-en.pdf";
import GitHub from "../Assets/Icons/icons8-github.svg";

function CvDeskEn() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1100) {
        navigate("/mob-en");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigate]);

  return (
    <>
      <div className="up-header">
        <a href={CvManuelEn} download>
          <p
            style={{
              marginTop: "20px",
              marginRight: "60px",
              fontWeight: "600",
              textDecoration: "none",
              textAlign: "right",
            }}
          >
            Download Resume
          </p>
        </a>
        <Link to="/">
          <p
            style={{
              marginTop: "20px",
              marginRight: "60px",
              fontWeight: "600",
              textDecoration: "none",
              textAlign: "right",
            }}
          >
            Spanish
          </p>
        </Link>
      </div>
      <section className="header" style={{ marginRight: "-35px" }}>
        <div>
          <div className="foto-manuel"></div>
        </div>
        <div className="right-section-header">
          <h2 style={{ marginBottom: "20px", marginTop: "0px" }}>
            MANUEL PASCUAL
          </h2>

          <p>
            I am a Web Developer with a background in Digital Marketing, where I
            discovered my passion for problem-solving, design, and the digital
            realm. Highly dedicated and self-motivated, I possess effective
            leadership and communication skills. I am seeking a challenging role
            as a web developer that allows me to make significant contributions
            to teams, drive project success, and expand my professional
            horizons.
          </p>
        </div>

        <div className="contact-column">
          <h3>CONTACT</h3>
          <p>
            <img src={Mail} width={10} style={{ marginRight: "7px" }} />
            <a href="mailto:manuelnpascual@gmail.com">
              manuelnpascual@gmail.com
            </a>
          </p>
          <p>
            <img src={Phone} width={10} style={{ marginRight: "7px" }} />

            <a href="https://wa.me/+5491151632960" target="_blank">
              +54 9 11 5163 2960
            </a>
          </p>
          <p>
            <img src={Phone} width={10} style={{ marginRight: "7px" }} />

            <a href="https://wa.me/+34634269453" target="_blank">
              +34 634 269 453
            </a>
          </p>
          <p>
            <img src={Linkedin} width={10} style={{ marginRight: "7px" }} />

            <a href="https://linkedin.com/in/pascualmanuel" target="_blank">
              linkedin.com/in/pascualmanuel
            </a>
          </p>
          <p>
            <img src={GitHub} width={10} style={{ marginRight: "7px" }} />

            <a href="https://github.com/pascualmanuel/" target="_blank">
              github.com/pascualmanuel/
            </a>
          </p>
          <div style={{ marginTop: "70px" }}>
            <h3>TOOLS</h3>
            <p style={{ paddingRight: "100px" }}>
              HTML 5 - CSS - JavaScript - React - Node - Express - PHP - Strapi
              - NextJS - MongoDB - MySQL - Wordpress - Tableau - Google
              Analytics - Google Data Studio - Adobe Creative Cloud
            </p>
            <h3>SKILLS</h3>
            <p>
              Problem-solving abilities.
              <br />
              Communication
              <br />
              Adaptive Capacity
              <br />
              Teamwork
              <br />
              Creativity
            </p>
            <h3>LANGUAGES</h3>
            <p>
              ENGLISH <br />
              advanced
              <br />
              SPANISH
              <br />
              native
            </p>
          </div>
        </div>
      </section>
      <hr className="separator" />
      <section className="section-two">
        <div>
          <h3>WORK EXPERIENCE</h3>
          <p style={{ marginTop: "-10px" }}>
            <span className="regular">LABBA STUDIO </span> <br />
            <span className="regular">Head of IT & Full Stack Developer </span>-
            May 2022 - present
            <br />
            <span>
              - Leadership of the IT team, overseeing projects and ensuring
              development quality.
            </span>
            <br />
            <span>
              - Development of web applications using React, Angular, and
              Next.js for the front-end, and Node.js and Express for the
              back-end.
            </span>
            <br />
            <span>
              - Maintenance and optimization of databases with MongoDB and
              MySQL.
            </span>
            <br />
            <span>
              - Collaboration with design and marketing teams for digital
              solutions.
            </span>
          </p>
          <p style={{ marginTop: "-10px", marginBottom: "-10px" }}>
            <span className="regular">LATECH FACTORY </span> <br />
            <span className="regular">Contractor Full Stack Developer</span> -
            May 2023 - present
            <br />
            <span>
              - Full development of web applications, including front-end and
              back-end.
            </span>
            <br />
            <span>
              - Building and customizing user interfaces and visual components.
            </span>
          </p>
          <p>
            <span className="regular">
              Tribu Data. Revolutionary Market Research Agency
            </span>
            <br />
            Software Development - USA FL Miami <br />
            March 2019 - February 2022
            <br />
            - Full Stack Developer. <br />
            - Processing, analysis, and visualization of data using Google Data
            Studio and Tableau.
            <br />
            - Web analytics and performance reporting.
            <br /> - Development and implementation of digital strategies,
            including Facebook advertising.
          </p>
          <h3>EDUCATION</h3>
          <p style={{ marginTop: "0" }}>
            <span className="regular">IRONHACK MADRID | Bootcamp </span> <br />
            Web development using front and back end technologies: HTML5, CSS3,
            JavaScript (ES6). Nodejs, Express.js, MongoDB. React.js. oct. 2021 -
            feb. 2022
          </p>
          <p style={{ marginTop: "0px" }}>
            <span className="regular">
              DIGITAL HOUSE | Full Stack Developer{" "}
            </span>
            <br />
            Web development using front and back end technologies: HTML5, CSS3,
            JavaScript (ES6). PHP, Laravel, MySQL. React.js. march 2019 - sept.
            2019
          </p>
          <p style={{ marginTop: "0px" }}>
            <span className="regular">
              Harvard University | CS50: Introduction to Computer Science
            </span>
            <br />
            Acquisition of fundamental knowledge in programming and
            problem-solving. Learning various programming languages, including
            C, Python, and JavaScript.
          </p>
          <p style={{ marginTop: "0px" }}>
            <span className="regular">
              EDUCACIÓN IT - Introduction to Python programming. 2020
            </span>
          </p>
          <p style={{ marginTop: "0px" }}>
            <span className="regular">
              EDUCACIÓN IT - JavaScript Advanced Developer. 2020
            </span>
          </p>
          <p style={{ marginTop: "0px" }}>
            <span className="regular">Google Analytics. 2019 </span>
          </p>
          <p style={{ marginTop: "0px" }}>
            <span className="regular">Godspell College </span>
            <br />
            Bilingual High School with a Humanities focus in 2016
          </p>
        </div>

        <div className="academica-section">
          <h3 className="proyect-h4">PROJECTS</h3>
          <Computer />
        </div>
      </section>
    </>
  );
}

export default CvDeskEn;
