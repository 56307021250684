import { Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import CvDeskEs from "./Pages/CvDeskEs";
import CvEsMob from "./Pages/CvEsMob";
import CvEnMob from "./Pages/CvEnMob";
import Computer from "./Pages/Computer";
import CvDeskEn from "./Pages/CvDeskEn";
import ReactGA from "react-ga";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<CvDeskEs />} />
        <Route path="/mob-es" element={<CvEsMob />} />
        <Route path="/mob-en" element={<CvEnMob />} />
        <Route path="/en" element={<CvDeskEn />} />
        <Route path="/compu" element={<Computer />} />
      </Routes>
    </>
  );
}

export default App;
